import { Controller } from 'stimulus'
import Swiper, {Navigation, Pagination} from 'swiper'
Swiper.use([Navigation, Pagination])

export default class extends Controller {
  static targets = ['slider', 'pagination', 'prev', 'next']

  connect() {
    this.initSlider()
  }

  initSlider() {
    new Swiper(this.sliderTarget, {
      slidesPerView: 1,
      speed: 600,
      autoHeight: true,
      lazyPreloadPrevNext: 1,
      centeredSlides: true,
      spaceBetween: 20,
      navigation: {
        prevEl: this.prevTarget,
        nextEl: this.nextTarget,
      },
      pagination: {
        el: this.paginationTarget,
        clickable: true,
      },
      breakpoints: {
        700: {
          slidesPerView: 2.3,
          centeredSlides: false,
        },
        1024: {
          slidesPerView: 2.75,
          centeredSlides: false,
          spaceBetween: 40,
        },
      },
    })
  }

}
